<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
        {{i18n('Release.EmployeeMaintenance')}}
      </span>
    </div>

    <Table />
  </div>
</template>

<style lang="scss" scoped>
.page-name {
  span {
    display: inline-block;
    background: #4166f8;
    background: linear-gradient(
      137deg,
      rgba(65, 102, 248, 0.2) 0%,
      #a4b7fc 100%
    );
    padding: 10px 20px;
    border-radius: 25px;
    letter-spacing: 2px;
  }
}
</style>

<script>
import Table from './Table.vue';

export default {
  components: {
    Table,
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
  }
};
</script>
